import { getParms } from './getParams';
import { getCookie } from './cookies';

export const metaParms = () => {
  return {
    utm_source: getParms('utm_source'),
    utm_campaign: getParms('utm_campaign'),
    adset_id: getParms('adset_id'),
    ad_name: getParms('ad_name'),
    adset_name: getParms('adset_name'),
    campaign_name: getParms('campaign_name'),
    placement: getParms('placement'),
    site_source_name: getParms('site_source_name'),
    ad_id: getParms('ad_id'),
    campaign_id: getParms('campaign_id'),
    fbclid: getParms('fbclid'),
    fbc: getCookie('_fbc'),
    fbp: getCookie('_fbp'),
    userAgent: navigator.userAgent,
    domain: 'ai.cleaner.app',
    device_type: '',
    site: window.location.href,
  };
};
