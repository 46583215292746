import { getParms } from './getParams';

export const tiktokAdjustLink = () => {
  const adjustURL =
    'https://app.adjust.com/' + process.env.GATSBY_ADJUST_TIKTOK_ID;
  const campaingName = getParms('campaign_name');
  const adset_name = getParms('adset_name');
  const ad_name = getParms('ad_name');
  const ttclid = getParms('ttclid');
  return {
    params: `?campaign=${campaingName}&adgroup=${adset_name}&creative=${ad_name}&label=${ttclid}`,
    adjustURL: adjustURL,
  };
};
