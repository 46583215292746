import { getParms } from './getParams';

export const metaAdjustLink = () => {
  const adjustURL =
    'https://app.adjust.com/' + process.env.GATSBY_ADJUST_META_ID;
  const campaingName = getParms('campaign_name');
  const adset_name = getParms('adset_name');
  const ad_name = getParms('ad_name');
  const fbclid = getParms('fbclid');
  return {
    params: `?campaign=${campaingName}&adgroup=${adset_name}&creative=${ad_name}&label=${fbclid}`,
    adjustURL: adjustURL,
  };
};
